<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto lg:max-w-7xl lg:mx-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full md:w-8/12 transform overflow-hidden rounded-2xl bg-black md:p-6 p-4 text-left align-middle shadow-xl transition-all"
            >

            <div class="absolute top-0 right-0 md:p-3 p-1"><i class="fas fa-times text-2xl text-gray-500 hover:text-gray-900 cursor-pointer" @click="closeModal"></i></div>
              <DialogTitle
                as="h3"
                class="text-lg leading-6 text-gray-900"
              >
              
              </DialogTitle>
              <div class="mt-5" style="height:40vh;">

        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/FNGYNCBT_Aw?si=unXJHlnUmOWEmKe4?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>



              </div>

              <div class="mt-4 flex justify-center">
                <button
                  type="button"
                  class="inline-flex justify-center  border border-transparent bg-black border-red-100 px-4 py-2 text-sm font-medium text-red-500 focus:outline-none focus-visible:ring-offset-2 w-full mx-2 md:mx-8 items-center cursor-pointer"
                  @click="closeModal"
                >
                  <i class="fa fa-times mr-3"></i>Close 
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'

const isOpen = ref(false)
const asset = ref(null);
const primaryImage = ref(null);
const placeholderImage = ref(false);
const primaryImageUrl = ref(null);
const images = ref(null);

function closeModal() {
  isOpen.value = false
}
function openModal(vehicle) {

  asset.value = vehicle;

  if(asset.value){
    getAssetImage();
  }

  isOpen.value = true
}

function getAssetImage() {

  if (asset.value.images) {
    let primaryImage = asset.value.images.filter((el) => {
      return el.position == 1;
    });


    if (primaryImage[0]) {
      primaryImageUrl.value =
        "uploads/vehicle_image/image/" +
        primaryImage[0].id +
        "/" +
        primaryImage[0].image;
    } else {
      primaryImageUrl.value = "/assets/img/brand/image-placeholder.webp";
      placeholderImage.value = true;
    }

    images.value = asset.value.images.filter((el) => {
      return el.position != 1;
    });
  }
  

}


defineExpose({openModal});
</script>
