<template>
  <div class="w-full  lg:flex">
    <div class="relative w-full z-20 ">

     <div @click="openVideoModal()" class=" p-3 group cursor-pointer transform transition duration-500 hover:scale-125  text-xs absolute bottom-0 w-full bg-mv-red hover:p-3 text-white text-center font-bold hover:shadow-md"><i class="far fa-play mr-3 group-hover:animate-pulse"></i>Watch our video to find out more about  who we are and what we can offer</div>


      <video-modal ref="video" />
      <NuxtImg
        provider="netlify"
        alt="MV Commercial Supersite at Airdrie"
        class="hidden md:flex w-full h-full object-cover" 
        :src='props.imageUrl'
        style="min-height: 500px; max-height: 900px;"
        sizes="md:100vw lg:100vw"
        :placeholder="[50, 25, 75, 5]"
      /> 

        <!-- <video autoplay muted playbackspeed="2"  :poster="props.imageUrl"
                    class="flex w-full h-full object-cover opacity-70 " 

            style="min-height: 500px; max-height: 900px;"
              >
        <source src="/assets/img/banner/bg-video.webm" type="video/webm"> 
        <source src="/assets/img/banner/bg-video_7.mp4" type="video/mp4">

      </video> -->


      <div class="right-0 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-20 xl:mt-44 " style=" min-width: 60rem; max-width: 70rem;">
      <!-- <div
        class="pl-5 lg:pl-10 lg:pt-12 lg:text-4xl text-2xl font-semibold tracking-wide leading-normal text-white w-full text-center mx-auto"
        :class="'top-' + props.lgTopPosition"
      >
        <div class="bg-mv-black px-5 bg-opacity-80 max-w-4xl font-black mx-auto p-4">
          {{ props.heading }}

          <div
            class="w-full text-center max-w-4xl mx-auto mt-5 text-lg font-normal leading-normal"
          >
            {{ props.subheading }}
          </div>
        </div>

        <a
          v-if="true"
          href="/"
          class="hidden mt-5 whitespace-nowrap font-semibold lg:inline-flex items-center justify-center px-5 py-3 border border-transparent rounded-md shadow-sm text-sm text-white bg-mv-red hover:bg-red-800"
        >
          <i class="fa fa-play mr-2"></i>Watch our video
        </a>

        <a
          v-if="props.customButton"
          :href="props.customButtonLink"
          class="hidden mt-5 whitespace-nowrap font-semibold lg:inline-flex items-center justify-center px-5 py-3 border border-transparent rounded-md shadow-sm text-sm text-white bg-mv-red hover:bg-gray-400"
        >
          {{ props.customButtonText }}
        </a>
      </div> -->

      <div class="flex flex-col flex-1  justify-center items-center">

      <TheSearch ></TheSearch>

         <!-- Icon Search -->
     
    <div class="hidden  md:grid grid-cols-9 mx-auto justify-between gap-x-5  my-3 bg-mv-black bg-opacity-90 p-3 border-b-4 border-b-mv-red banner">
        <icon-search @hover="(e) => hoverIcon = e" v-for="icon in searchIcons" :key="icon" :type="icon" />
      </div>   
          <transition name="quick-fade">
   
       <div v-if="hoverIcon" class="bg-white absolute -bottom-10 text-mv-black shadow-md font-bold px-12 py-2 rounded-sm capitalize banner border-b-4 border-mv-red">  {{ hoverIcon  }}s</div>
       </transition>

      </div> 

      <!-- mobile buttons -->

      <a
        v-if="props.enquireButton"
        href="/contact"
        class="lg:hidden w-full whitespace-nowrap font-semibold inline-flex items-center justify-center px-5 py-3 border border-transparent text-sm text-white bg-mv-black hover:bg-red-500"
      >
        Enquire now
      </a>

      <a
        v-if="props.customButton"
        :href="props.customButtonLink"
        class="lg:hidden w-full whitespace-nowrap font-semibold inline-flex items-center justify-center px-5 py-3 border border-transparent text-sm text-white bg-mv-black hover:bg-yellow-400"
      >
        {{ props.customButtonText }}
      </a>
    </div>
       

    
    </div>

          </div>

  
</template>


<script setup>

const props = defineProps({
  imageUrl: { String },
  heading: { String },
  subheading: { String },
  donateButton: { Boolean },
  enquireButton: { Boolean },
  customButton: { Boolean },
  customButtonText: { String },
  customButtonLink: { String },
  lgTopPosition: { type: Number, default: 12 },
});

const hoverIcon = ref("");

const video = ref();



const searchIcons = [
  "box_and_curtain",
  "crane_truck",
  "dropside_and_flatbed",
  "skip_and_hookloader",
  "tipper_and_grab",
  "tractor_unit",
  "van",
  "trailer",
  "car"
];


function openVideoModal() {
   video.value.openModal();
}


</script>

<style scoped>
.fade-enter-active {
  transition: opacity 3s ease-in-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-200px);
  opacity: 0;
}

.slide-fade-left-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-left-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-left-enter-from,
.slide-fade-left-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}

.slide-fade-up-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-up-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-up-enter-from,
.slide-fade-up-leave-to {
  transform: translateY(20px);
  opacity: 0;
}




.quick-fade-enter-active,
.quick-fade-leave-active {
  transition: opacity 0.5s ease;
}

.quick-fade-enter-from,
.quick-fade-leave-to {
  opacity: 0;
}

</style>
