<template>
  <div
    class="bg-mv-black bg-opacity-90 pt-24 pb-4 px-4 w-full mx-auto md:pt-2   md:mt-20 lg:mt-0"
  >
    <div
      @mouseover="hoverKeywordSearch = true"
      @mouseleave="hoverKeywordSearch = false"
      class="group relative"
    >
      <div class="text-white text-center font-bold mt-1 mb-2 text-xl md:text-lg">
        Let's find your next <span class="text-mv-green">Truck, Trailer or Van</span>
      </div>

      <transition name="search-fade" mode="out-in">
        <div
          class="md:flex space-y-5 md:space-y-0 gap-x-5 content-center w-full"
          key="asset-search"
          v-if="!keywordSearch"
        >
          <Transition name="search-fade">
            <div
              @click="keywordSearch = true"
              v-show="true"
              class="opacity-0 md:opacity-100 ease-in-out absolute md:group-hover:flex top-0 right-0 z-50 uppercase cursor-pointer text-xs font-bold underline text-gray-200"
            >
              Keyword Search
            </div>
          </Transition>
          <div class="md:w-4/12">
            <Listbox as="div" v-model="manufacturerSelected">
              <div class="relative">
                <ListboxButton
                  class="relative w-full cursor-default bg-white py-3 rounded-sm pl-4 pr-10 text-left text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 sm:leading-6"
                >
                  <span class="block truncate">
                    <span v-if="manufacturerSelected">{{
                      manufacturerSelected.manufacturer_name
                    }}</span>
                    <span v-else>All Manufacturers</span>
                  </span>
                  <span
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronDownIcon class="h-5 w-5 text-gray-700" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="absolute mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" style="z-index: 10;"
                  >
                    <ListboxOption
                      as="template"
                      v-for="manufacturer in manufacturersList"
                      :key="manufacturer.id"
                      :value="manufacturer"
                      v-slot="{ active, selected }"
                      @click="
                        addValueToFilterList('Makes', manufacturer.manufacturer_name)
                      "
                    >
                      <li
                        :class="[
                          active ? 'bg-blue-600 text-white' : 'text-gray-700',
                          'relative cursor-default select-none py-2 pl-3 pr-3',
                        ]"
                      >
                        <span
                          class="flex justify-between"
                          :class="[
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          ]"
                          ><span class="truncate mr-3" style="max-width: 75%">{{
                            manufacturer.manufacturer_name
                          }}</span>
                          <span
                            class="w-8 border-mv-r align-middle items-center rounded-md font-bold text-white bg-mv-black px-1 justify-center text-xs flex"
                            ><span class="pt-0.5">{{ manufacturer.total }}</span></span
                          ></span
                        >

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-blue-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>

          <!-- <div class="md:w-4/12 hidden">
        <Listbox as="div" v-model="modelSelected">
          <div class="relative">
            <ListboxButton
              class="relative w-full cursor-default bg-white py-3 rounded-sm pl-4 pr-10 text-left text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 sm:leading-6"
            >
              <span class="block truncate">
                <span v-if="modelSelected">{{ modelSelected.title }}</span>
                <span v-else>All Models</span>
              </span>
              <span
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <ChevronDownIcon
                  class="h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition ease-in duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none "
              >
                <div
                  class="text-black text-center font-bold py-2 border"
                  v-for="(manufacturer, key) in modelsList"
                  :key="manufacturer"
                >
                  {{ key }} Models
                  <ListboxOption
                    as="template"
                    v-for="model in manufacturer"
                    :key="model.id"
                    :value="model[0]"
                    v-slot="{ active, selected }"
                    @click="addValueToFilterList('Models', model[0].title)"
                  >
                    <li
                      :class="[
                        active ? 'bg-blue-600 text-white' : 'text-gray-700',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      ]"
                    >
                      <span
                        class="flex justify-between"
                        :class="[
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate',
                        ]"
                        >{{ model[0].title }}
                        <span
                          class="w-5 h-5 rounded-full bg-black text-white justify-center text-xs flex"
                          ><span class="pt-0.5">{{
                            model[0].total
                          }}</span></span
                        ></span
                      >

                      <span
                        v-if="selected"
                        :class="[
                          active ? 'text-white' : 'text-blue-600',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        ]"
                      >
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </div>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div> -->

          <!-- body -->

          <div class="md:w-4/12">
            <Listbox as="div" v-model="bodyTypeSelected">
              <div class="relative">
                <ListboxButton
                  class="relative w-full cursor-default bg-white py-3 rounded-sm pl-4 pr-10 text-left text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 sm:leading-6" 
                >
                  <span class="block truncate">
                    <span v-if="bodyTypeSelected">{{ bodyTypeSelected.title }}</span>
                    <span v-else>All Body Types</span>
                  </span>
                  <span
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronDownIcon class="h-5 w-5 text-gray-700" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="absolute mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" style="z-index: 10;"
                  >
                    <div class="text-black text-center font-bold py-2 border">
                      <ListboxOption
                        as="template"
                        v-for="bodyType in bodyTypeList"
                        :key="bodyType"
                        :value="bodyType"
                        v-slot="{ active, selected }"
                        @click="addValueToFilterList('Body Type', bodyType.title)"
                      >
                        <li
                          :class="[
                            active ? 'bg-blue-600 text-white' : 'text-gray-700',
                            'relative cursor-default select-none py-2 pl-3 pr-3',
                          ]"
                        >
                          <span
                            class="flex justify-between"
                            :class="[
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            ]"
                            ><span class="truncate mr-3" style="max-width: 75%">{{
                              bodyType.title
                            }}</span>
                            <span
                              class="w-8 border-mv-r align-middle items-center rounded-md font-bold text-white bg-mv-black px-1 justify-center text-xs flex"
                              >{{ bodyType.total }}</span
                            ></span
                          >

                          <span
                            v-if="selected"
                            :class="[
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </div>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>

          <!--Condition -->
          <div class="md:w-3/12">
            <Listbox as="div" v-model="saleTypeSelected">
              <div class="relative">
                <ListboxButton
                  class="relative w-full cursor-default bg-white py-3 rounded-sm pl-4 pr-10 text-left text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 sm:leading-6"
                >
                  <span class="block truncate">{{ saleTypeSelected.name }}</span>
                  <span
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronDownIcon class="h-5 w-5 text-gray-700" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="absolute z-10 mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <ListboxOption
                      as="template"
                      v-for="saleType in saleTypes"
                      :key="saleType.id"
                      :value="saleType"
                      v-slot="{ active, selected }"
                      @click="addValueToFilterList('Condition', saleType.name)"
                    >
                      <li
                        :class="[
                          active ? 'bg-blue-600 text-white' : 'text-gray-700',
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                        ]"
                      >
                        <span
                          :class="[
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          ]"
                          >{{ saleType.name }}</span
                        >

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-blue-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>

          <!-- mobile keyword search -->
          <div class="md:hidden w-full text-white text-center pt-5 border-t">
            <!-- <div class="font-bold pb-1 text-xs">OR</div> -->

            <input
              type="text"
              placeholder="Search by registration or vehicle..."
              class="border border-mv-border-gray rounded-sm px-5 pl-2 pr-10 py-3 flex flex-col w-full text-gray-700 shadow-sm ring-1 ring-inset ring-mv-header-gray focus:outline-none focus:ring-2 focus:ring-gray-300 sm:leading-6"
              v-model="keyword"
              @keyup.enter="onEnter"
            />
          </div>

          <div class="md:w-2/12 w-full">
            <NuxtLink
              href="/main-search"
              class="bg-mv-green hover:bg-green-700 text-white rounded-sm font-semibold px-3 py-3 flex"
              ><div class="w-full text-center">Search</div></NuxtLink
            >
          </div>
        </div>

        <div key="keyword-search" v-else>
          <Transition name="search-fade">
            <div
              v-show="hoverKeywordSearch && keywordSearch"
              @click="keywordSearch = false"
              class="opacity-0 md:opacity-100 absolute group-hover:flex top-0 right-0 z-50 uppercase cursor-pointer text-xs font-bold underline text-white"
            >
              Back
            </div>
          </Transition>

          <div class="md:flex space-y-5 md:space-y-0 items-center">
            <div class="text-sm"></div>
            <div class="flex items-center relative w-full md:space-x-10">
              <input
                type="text"
                placeholder="Search by registration, vehicle details or equipment ..."
                class="border border-mv-border-gray rounded-sm px-5 pl-2 pr-10 py-3 flex flex-col w-full text-gray-700 shadow-sm ring-1 ring-inset ring-mv-header-gray focus:outline-none focus:ring-2 focus:ring-gray-300 sm:leading-6"
                v-model="keyword"
                @keyup.enter="onEnter"
              />
              <div
                v-if="keyword"
                class="absolute right-5 cursor-pointer z-50"
                @click="clearKeyword"
              >
                <i class="fa fa-times ml-3" />
              </div>
            </div>
            <div class="md:w-2/12 w-full md:ml-5">
              <NuxtLink
                href="/main-search"
                class="bg-mv-green hover:bg-green-700 text-white rounded-sm font-semibold px-3 py-3 flex"
                ><div class="w-full text-center">Search</div></NuxtLink
              >
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { useFiltersStore } from "~/store/filters";
import { storeToRefs } from "pinia";

const router = useRouter();

const filtersStore = useFiltersStore();
const { addValueToFilterList, removeFilter } = filtersStore;
const { filtersList } = storeToRefs(filtersStore);
const { clearFilters } = filtersStore;

const hoverKeywordSearch = ref(false);

const config = useRuntimeConfig();

const keywordSearch = ref(false);

const keyword = ref("");

watch(keyword, () => {
  if (keyword.value.length > 2) {
    addValueToFilterList("Keyword", keyword.value);
  }

  if (keyword.value == "") {
    //hack! to make sure the filter is cleared after the user stops typing
    setTimeout(() => {
      clearFilters();
    }, 200);
  }
});

function clearKeyword() {
  keyword.value = "";
  clearFilters();
}

import { ref } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/20/solid";

const nuxtApp = useNuxtApp();


const { data: manufacturersList } = await useFetch(
  config.public["VEHICLE_API_BASE_URL"] +
    "/website-api/get-filter-selects/manufacturer_name",
  {
    params: { filtersList },
    
  }
);

const { data: modelsList } = await useFetch(
  config.public["VEHICLE_API_BASE_URL"] + "/website-api/get-filter-selects/model_name",
  {
    params: { filtersList },
  }
);

const { data: bodyTypeList } = await useFetch(
  config.public["VEHICLE_API_BASE_URL"] +
    "/website-api/get-filter-selects/body_type_name",
  {
    params: { filtersList },
  }
);

const saleTypes = [
  { id: 1, name: "New and Used" },
  { id: 2, name: "New" },
  { id: 3, name: "Used" },
];

const saleTypeSelected = ref(saleTypes[0]);
const manufacturerSelected = ref();
const modelSelected = ref();
const bodyTypeSelected = ref();

function onEnter() {
  router.push({ path: "/main-search" });
}
</script>

<style scoped>
.search-fade-enter-active,
.search-fade-leave-active {
  transition: opacity 0.5s;
}
.search-fade-enter,
.search-fade-leave-to {
  opacity: 0;
}
</style>
