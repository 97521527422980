<template>
  <div class="group flex flex-col">

  <NuxtLink
    to="/main-search"
    @mouseover="hoverIcon"
    @mouseleave="clearHover"
    class=" group-hover:bg-mv-red h-full w-full px-4  rounded-md py-2 cursor-pointer ease-in-out "
    @click="addTypeToFilterList()"
  >
    <NuxtImg
      provider="netlify"
      :src="`/assets/img/brand/search-icons/white/${props.type}.webp`"
      class="mx-auto w-full"
      alt="Search for {{ props.type.replace(/_/g, ' ') }} at MV Commercial"
      sizes="md:100vw lg:100vw"
    />
    <div class="w-full text-center mt-5 font-semibold tracking-widest">({{ data?.count }})</div>
    
  </NuxtLink>
  <!-- <div class="flex text-center uppercase px-2">{{ props.type.replace(/_/g, ' ') }}</div> -->
  </div>
</template>



<script setup>
import { useFiltersStore } from "~/store/filters";
import { useWebsiteModeStore } from "~/store/websiteMode";
  import { storeToRefs } from "pinia";

  const { $swal } = useNuxtApp();


const websiteModeStore = useWebsiteModeStore();

  const { mode } = storeToRefs(websiteModeStore);

const filtersStore = useFiltersStore();
const { clearFilters } = filtersStore;
const { addValueToFilterList, removeFilter, getFilterList } = filtersStore;

const config = useRuntimeConfig();
const props = defineProps(["type"]);

const { data, error, pending, execute, refresh } = await useFetch(
  config.public['VEHICLE_API_BASE_URL'] + "/website-api/get-icon-count/" + props.type + "/" + mode.value
);

function addTypeToFilterList() {
  if (props.type == "box_and_curtain") {
    addValueToFilterList("Body Type", "Box");
    addValueToFilterList("Body Type", "Curtainsider");
  }

  if (props.type == "crane_truck") {
    addValueToFilterList("all_crane_trucks", "All Crane Trucks");
  }

    if (props.type == "dropside_and_flatbed") {
      addValueToFilterList("Body Type", "Dropside");
      addValueToFilterList("Body Type", "Flatbed");
    }

    if (props.type == "skip_and_hookloader") {
      addValueToFilterList("Body Type", "Skip Loader");
      addValueToFilterList("Body Type", "Hook Loader");
    }

    if (props.type == "tipper_and_grab") {
      addValueToFilterList("Body Type", "Tipper");
      addValueToFilterList("Body Type", "Tipper Grab");
    }

    if (props.type == "tractor_unit") {
      addValueToFilterList("Body Type", "Tractor Unit");
    }

    if (props.type == "van") {
      addValueToFilterList("Body Type", "Van");
    }

    if (props.type == "trailer") {
      addValueToFilterList("Body Type", "Trailers");
    }

    if (props.type == "car") {
      addValueToFilterList("Body Type", "Car");
    }


}

function hoverIcon() {
  emit("hover", props.type.replace(/_/g, ' '));
}

function clearHover() {
  emit("hover", "");
}



const emit = defineEmits(["hover"]);

</script>